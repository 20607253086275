// Import the localized messages
import { createIntl, createIntlCache } from 'react-intl';
import English from '../../lang/en.json';
import French from '../../lang/fr.json';

const intl = () => {
  const cache = createIntlCache();
  const locale = localStorage.getItem('locale') || 'en';
  const messages = locale === 'en' ? English : French;

  return createIntl({ locale, messages }, cache);
};

const { formatMessage } = intl();

const genderMessages = {
  MAN: formatMessage({ defaultMessage: 'Male' }),
  WOMAN: formatMessage({ defaultMessage: 'Female' }),
  'NON-BINARY': formatMessage({
    defaultMessage: 'Gender Non-Binary or Expansive',
  }),
  TRANSGENDER: formatMessage({ defaultMessage: 'Transgender' }),
  'PREFER-NO-SAY': formatMessage({ defaultMessage: 'Prefer not to say' }),
  'DO-NOT-KNOW': formatMessage({ defaultMessage: 'Do not know' }),
  'GENDER-FLUID-NON-CONFORMING': formatMessage({
    defaultMessage: 'Gender Fluid/Nonconforming',
  }),
  'GENDER-QUESTIONING': formatMessage({ defaultMessage: 'Gender Questioning' }),
  GENDERQUEER: formatMessage({ defaultMessage: 'Gender Queer' }),
  INTERSEX: formatMessage({ defaultMessage: 'Intersex' }),
  OTHER: formatMessage({ defaultMessage: 'Other' }),
  'TRANS-MAN': formatMessage({ defaultMessage: 'Trans Man' }),
  'TRANS-WOMAN': formatMessage({ defaultMessage: 'Trans Woman' }),
  'TWO-SPIRIT': formatMessage({ defaultMessage: 'Two Spirit' }),
};

const formatGender = genderKey => {
  // Safely handle null/undefined and lowercase the gender key
  const key = genderKey || 'DO-NOT-KNOW';
  console.log(genderMessages);
  console.log(key);

  return genderMessages[key];
};
export default formatGender;
